import React, {useState} from 'react';
import ReactDOM from "react-dom";
import Table from "../admin/Table";

const InvestorDistributions = props => {
    const {setError, headers, columnTypes, tableData, tableName, endpoint, displayTotal} = props;

    const [currentData, setCurrentData] = useState(tableData);
    const [currentDateRange, setDateRange] = useState('all');
    const [currentCustomStartDate, setCustomStartDate] = useState(new Date());
    const [currentCustomEndDate, setCustomEndDate] = useState(new Date());
    const [customEnableState, setCustomEnableState] = useState(true);
    const url = new URL(window.location.href);

    const handleDateFilterChange = event => {
        let dateFilter = event.target.value;
        if (dateFilter !== 'custom') {
            setCustomEnableState(true);
        } else {
            setCustomEnableState(false);
        }
        setDateRange(dateFilter);
    }

    const handleCustomStartDateChange = event => {
        setCustomStartDate(event.target.value);
    }

    const handleCustomEndDateChange = event => {
        setCustomEndDate(event.target.value);
    }

    return (
        <>
            <form action={window.location.origin + endpoint} className={"form"}>
                <div className={"form__filter form__filter--active"}>
                    <div className={"form__row form__row--align-flexend"}>
                        <div className={"form__column"}>
                            <label className="form__label">Timeframe:</label>
                            <select name="date" id="date" data-filter={"timeframe-range"} className={"form__input--filters"} onChange={handleDateFilterChange}>
                                <option selected={url.searchParams.get('date') === 'ytd'} value="ytd" data-filter-option={"ytd"}>Year-to-Date</option>
                                <option selected={url.searchParams.get('date') === 'quarter'} value="quarter" data-filter-option={"quarter"}>Current Quarter</option>
                                <option selected={url.searchParams.get('date') === 'all'} value="all" data-filter-option={"all"}>All</option>
                                <option selected={url.searchParams.get('date') === 'custom'} value="custom" data-filter-option={"custom"}>Custom</option>
                            </select>
                        </div>

                        <input type="submit" value="Submit" value="Filter" className="button button--blue-opaque form__filter--button"/>
                    </div>
                </div>


                <div className={"form__filter"} id={"custom-date-range"}>
                    <div className={"form__column"}>
                        <label className="form__label">Custom Dates: </label>
                        <div className={"form__row form__row--align-center"}>
                            <input type="date" id="start" name="start_date" className={"form__input--filters"} value={currentCustomStartDate} max={new Date()} onChange={handleCustomStartDateChange} disabled={customEnableState}/>
                            <label className={"form__label--large"}>to</label>
                            <input type="date" id="end" name="end_date" className={"form__input--filters"} value={currentCustomEndDate} max={new Date()} onChange={handleCustomEndDateChange} disabled={customEnableState}/>
                        </div>
                    </div>
                </div>

            </form>

            <Table tableData={tableData} columnTypes={columnTypes} headers={headers} displayTotal={displayTotal}/>
        </>

        );
};

if (document.querySelector('[data-react-component="Investor-Distributions"]')) {
    ReactDOM.render(<InvestorDistributions {...tableReactData} />, document.querySelector('[data-react-component="Investor-Distributions"]'));
}

export default InvestorDistributions;
