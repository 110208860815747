import React, {useEffect} from 'react';
import {string, arrayOf} from 'prop-types';

const Error = props => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    const errors = props.errors.map((error, idx) => {
        return (<li key={idx}>{error}</li>);
    });
    return (
        <ul className="msg msg__error">
            {errors}
        </ul>
    );
};

Error.propTypes = {
    errors: arrayOf(string)
}

export default Error;
