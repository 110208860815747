function Navigation() {
    this.init = function() {
        const self = this;
        this.navButton = document.getElementById('nav-button');
        this.navLinks = document.getElementById('nav-links');
        this.navButton.addEventListener('click', self.handleNavClick.bind(self));
        document.addEventListener('click', self.handleDocClick.bind(self));
    };

    this.handleNavClick = function() {
        this.navLinks.classList.toggle('navbar__links--visible');
    };

    this.handleDocClick = function(ev) {
        const isClickInside = this.navButton.contains(ev.target) || this.navLinks.contains(ev.target);
        if (!isClickInside) {
            this.navLinks.classList.remove('navbar__links--visible');
        }
    };
}

window.bridgeNavigation = new Navigation();
document.addEventListener('DOMContentLoaded', window.bridgeNavigation.init.bind(window.bridgeNavigation));
