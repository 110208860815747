/**
 * Get a key value list from a keyed string, ex:
 * "my/string{key}/with{some}keys/in/it"
 * outputs an Object:
 * {
 *  key: "{key}"
 *  some: "{some}"
 * }
 * @param string
 * @returns {{}}
 */
export const getStringKeys = string => {
    const urlRegex = new RegExp(/\{(.*?)\}/g);
    const matches = string.matchAll(urlRegex);
    const replaceableKeys = {};
    for (const match of matches) {
        if (!match) {
            continue;
        }
        replaceableKeys[match[1]] = match[0];
    }
    return replaceableKeys;
};
