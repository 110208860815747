import React, {useState} from 'react';
import ReactDOM from "react-dom";
import DocViewToggle from "./DocViewToggle";
import ViewButton from "../admin/ViewButton";

const DocumentsInvest = props => {
    const [error, setError] = useState(null);
    const auxColumns = [
        ViewButton
    ];

    return (<DocViewToggle {...props} error={error} setError={setError} auxColumns={auxColumns} />);
};

if (document.querySelector('[data-react-component="DocumentsInvest"]')) {
    ReactDOM.render(<DocumentsInvest {...tableReactData} />, document.querySelector('[data-react-component="DocumentsInvest"]'));
}

export default DocumentsInvest;
