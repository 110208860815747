import React, {useState} from 'react';
import ReactDOM from "react-dom";
import Search from "./Search";
import DeleteCheckbox from "./DeleteCheckbox";

const Investments = props => {
    const [error, setError] = useState(null);
    const auxColumns = [
        DeleteCheckbox
    ];

    return (
        <>
            <h2 className="header2 header2--blue text-align-center">All Investments</h2>
            <a href={props.endpoint + "create"} className="form__button--add no-print">
                <img src="/images/add.png" alt="add button" className={"form__icon form__icon--add"}/>
                <h4 className="header4--blue">Add New Investment</h4>
            </a>

            <a href={props.endpoint + "attributes"} className="form__button--add no-print">
                <img src="/images/add.png" alt="add button" className={"form__icon form__icon--add"}/>
                <h4 className="header4--blue">Manage Data Types</h4>
            </a>
            <Search showExport={true} showPrint={true} showImport={true} showTemplate={true}  {...props} error={error}
                    setError={setError} auxColumns={auxColumns}/>
        </>

    );
};

if (document.querySelector('[data-react-component="Investments"]')) {
    ReactDOM.render(
        <Investments {...tableReactData} />, document.querySelector('[data-react-component="Investments"]'));
}

export default Investments;
