import React, {useState} from 'react';
import ReactDOM from "react-dom";
import Search from "./Search";

const POECreate = props => {
    const [error, setError] = useState(null);

    return (
        <>
            <Search showExport={false} showPrint={false} showImport={false} showTemplate={false} {...props}
                    error={error} setError={setError}/>
        </>
    );
};

if (document.querySelector('[data-react-component="poe-create"]')) {
    ReactDOM.render(<POECreate {...tableReactData} />, document.querySelector('[data-react-component="poe-create"]'));
}

export default POECreate;
