document.addEventListener('DOMContentLoaded', function (ev) {
    let checkboxes = jQuery('input[name*="all"]');

    checkboxes.click(function (event) {
        let roleType = jQuery(this).data('selector');
        let selectAll = jQuery('[data-role-type*="' + roleType + '"]');
        if (jQuery(`[data-selector="${roleType}"]`).prop('checked')) {
            selectAll.each(function() {
                this.checked=true;
            });
        } else {
            selectAll.each(function() {
                this.checked=false;
            });
        }
    });
});
