let accordionMenu = document.querySelectorAll("[data-toggle]");

for (let i = 0; i < accordionMenu.length; i++) {
    accordionMenu[i].addEventListener("click", function(ev) {
        let menuSelection = ev.currentTarget.dataset.toggle;
        localStorage.setItem('menuType', menuSelection);
        updateActiveMenu(menuSelection);
    });
}

window.onload = function(ev) {
    let menuSelection = localStorage.getItem('menuType');
    updateActiveMenu(menuSelection);
}

function updateActiveMenu(menuSelection) {
    let hideMenu = document.querySelectorAll("[data-toggle-show]");
    let activeMenu = document.querySelector(`[data-toggle-show='${menuSelection}']`);
    if (localStorage.getItem('menuType') === menuSelection) {
        if (activeMenu !== null && !activeMenu.classList.contains('active')) {
            for (let i = 0; i < hideMenu.length; i++) {
                hideMenu[i].classList.remove('active');
            }
            activeMenu.classList.add('active');
        }
    }
}
