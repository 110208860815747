/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');
require('./mask-phone-input');
require('./toggle-all-checkboxes');
require('./accordion');
require('./display-file-input');
require('./nav');
require('./mobile-side-nav');
require('./toggle-side-nav');
require('./flextable-tabs');
require('./custom-dates-filter');
require('./confirm-delete');


/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/admin/Search');
require('./components/admin/Table');
require('./components/admin/Users');
require('./components/admin/Properties');
require('./components/admin/Investments');
require('./components/admin/Documents/Documents');
require('./components/admin/PropertyOwnershipEntity');
require('./components/admin/InvestorOwnershipEntity');
require('./components/investor/DocumentsInvest');
require('./components/investor/Distributions');
require('./components/admin/Documents/DocumentsForm');
require('./components/admin/Documents/DocumentLine');
require('./components/investor/DocViewToggle');
require('./components/admin/Attributes');
require('./components/Accordion');
require('./components/admin/Distributions');
require('./components/admin/Contributions');
require('./components/investor/PerformanceSummary');
require('./components/admin/POECreate');
require('./components/admin/IOECreate');
