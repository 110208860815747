let tabs = document.getElementsByClassName("flextable__tabs");

for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener("click", function(ev) {
        let selection = ev.target.dataset.selector;
        let allTables = document.querySelectorAll("[data-table-selection*='all']");
        let allTabs = document.querySelectorAll("[data-selector*='all']");
        let selectedTable = document.querySelector(`[data-table-selection*='${selection}']`);
        let selectedTab = ev.currentTarget;

        allTabs.forEach((tabEl) => {
            tabEl.classList.remove("flextable__tabs--active");
        });
        selectedTab.classList.add("flextable__tabs--active");

        allTables.forEach((tableEl) => {
            tableEl.classList.remove("flextable__active");
        });
        selectedTable.classList.add("flextable__active");
    });
}
