import React, {useState} from 'react';
import ReactDOM from "react-dom";
import Table from "../admin/Table";

const PerformanceSummary = props => {
    const {setError, headers, columnTypes, tableData, tableName, endpoint} = props;

    const [currentData, setCurrentData] = useState(tableData);
    const [currentDateRange, setDateRange] = useState('all');
    const [currentCustomStartDate, setCustomStartDate] = useState(new Date());
    const [currentCustomEndDate, setCustomEndDate] = useState(new Date());
    const [customEnableState, setCustomEnableState] = useState(true);

    const handleDateFilterChange = event => {
        let dateFilter = event.target.value;
        if (dateFilter !== 'custom') {
            setCustomEnableState(true);
        } else {
            setCustomEnableState(false);
        }
        setDateRange(dateFilter);
    }

    const handleCustomStartDateChange = event => {
        setCustomStartDate(event.target.value);
    }

    const handleCustomEndDateChange = event => {
        setCustomEndDate(event.target.value);
    }

    return (
        <>
            <Table tableData={tableData} columnTypes={columnTypes} headers={headers}/>
        </>

    );
};

if (document.querySelector('[data-react-component="PerformanceSummary"]')) {
    ReactDOM.render(
        <PerformanceSummary {...tableReactData} />, document.querySelector('[data-react-component="PerformanceSummary"]'));
}

export default PerformanceSummary;
