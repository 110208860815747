document.addEventListener('DOMContentLoaded', function (ev) {
    const fileInputElement = document.querySelectorAll("input[type='file']");
    const docsFormReact = document.querySelectorAll("[data-react-component='DocumentsForm']")
    if (fileInputElement && !docsFormReact) {
        fileInputElement.forEach( el => {
            el.addEventListener('change', function (event) {
                el.previousElementSibling.innerHTML = event.target.value.split('\\').pop();
            });
        });
    }
});
