import React from 'react';

const DeletePopup = props => {
    const {onConfirm,onCancel} = props;

    return  (
        <div className={'form__popup'}>
            <div className={'form__popup-inner'}>
                <div className={'form__popup-text'}>
                    Are you sure you want to delete the selected rows?
                </div>
                <div>
                    <button onClick={onConfirm} type={'button'} className={'button button--red-opaque form__button--delete'}>Delete</button>
                    <button onClick={onCancel} type={'button'} className={'button'}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;
