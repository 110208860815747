import React, {useState} from 'react';
import ReactDOM from "react-dom";
import Search from "../Search";
import DeleteCheckbox from "../DeleteCheckbox";
import ViewButton from "../ViewButton";

const Documents = props => {
    const [error, setError] = useState(null);
    const auxColumns = [
        ViewButton,
        DeleteCheckbox
    ]

    return (
        <>
            <h2 className="header2 header2--blue text-align-center">All Documents</h2>
            <a href={props.endpoint + "create"} className="form__button--add no-print">
                <img src="/images/add.png" alt="add button" className={"form__icon form__icon--add"}/>
                <h4 className="header4--blue">Add New Documents</h4>
            </a>
            <Search showPrint={true} showExport={true} {...props} error={error} setError={setError}
                    auxColumns={auxColumns}/>
        </>

    );
};

if (document.querySelector('[data-react-component="Documents"]')) {
    ReactDOM.render(<Documents {...tableReactData} />, document.querySelector('[data-react-component="Documents"]'));
}

export default Documents;
