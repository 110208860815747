document.addEventListener('DOMContentLoaded', function (ev) {
    if (document.getElementById("custom-date-range")) {
        let customDatesFilter = document.getElementById("custom-date-range")
        let selectElement = document.querySelector("[data-filter='timeframe-range']");
        selectElement.addEventListener('change', function(ev) {
            let selectedFilter = selectElement.value;
            if (selectedFilter === 'custom') {
                customDatesFilter.classList.add('form__filter--active');
            }
        })
    }
})
