/**
 * Format data based on list of formattable types
 * @param fieldOutput {string} data to be formatted
 * @param formatting {Array} list of strings of formatting types
 * @returns {string|*} formatted data
 */
const getAppliedFormatting = (fieldOutput, formatting) => {
    if (fieldOutput == null || !formatting) {
        return fieldOutput;
    }
    if (formatting.includes('boolean')) {
        fieldOutput = fieldOutput ? "Yes" : "No";
    }
    if (formatting.includes('capitalize')) {
        fieldOutput = fieldOutput.split(' ').map(capitalize).join(' ');
    }
    //todo: this shouldn't default to email fields only
    if (formatting.includes('checkbox')) {
        return (
          <>
              <input type="checkbox" name={'email[' + fieldOutput + ']'} id={'email[' + fieldOutput + ']'} className={"form__input--checkboxes"}/>
              <label htmlFor={'email[' + fieldOutput + ']'}>{fieldOutput}</label>
          </>
        );
    }
    if (formatting.includes('currency')) {
        fieldOutput = `${Number(fieldOutput).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
    }
    if (formatting.includes('dateMY')) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let date = new Date(fieldOutput);
        fieldOutput = months[date.getMonth()] + " " + date.getFullYear()
    }
    if (formatting.includes('dateFull')) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let date = new Date(fieldOutput);
        fieldOutput = months[date.getMonth()] + " " + date.getDay() + ", " + " " + date.getFullYear()
    }
    if (formatting.includes('percent') && typeof(fieldOutput) == "number") {
        fieldOutput = `${(fieldOutput * 100).toFixed(2)}%`;
    }
    if (formatting.includes('phone')) {
        let cleaned = ('' + fieldOutput).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            fieldOutput = '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }
    if (formatting.includes('return')) {
        fieldOutput = `${(fieldOutput * 1).toFixed(2)}x`;
    }
    return fieldOutput;
};

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default getAppliedFormatting;
