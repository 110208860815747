import React, {useState} from 'react';
import Table from "../admin/Table";
//todo Documents aren't currently yet in use, so this isn't used yet. Needs some TLC
const DocViewToggle = props => {
    const {setError, headers, columnTypes, tableData, tableName, docTypes, endpoint} = props;

    const [currentData, setCurrentData] = useState(tableData);
    const [currentDocType, setDocType] = useState('all');
    const [currentDateRange, setDateRange] = useState('all');
    const [currentCustomStartDate, setCustomStartDate] = useState(new Date());
    const [currentCustomEndDate, setCustomEndDate] = useState(new Date());
    const [customEnableState, setCustomEnableState] = useState(true);

    const handleDocFilterChange = event => {
        setDocType(event.target.value);
    }

    const handleDateFilterChange = event => {
        let dateFilter = event.target.value;
        if (dateFilter !== 'custom') {
            setCustomEnableState(true);
        } else {
            setCustomEnableState(false);
        }
        setDateRange(dateFilter);
    }

    const handleCustomStartDateChange = event => {
        setCustomStartDate(event.target.value);
    }

    const handleCustomEndDateChange = event => {
        setCustomEndDate(event.target.value);
    }

    const getDocTypes = docTypes.map(type => {
        return (
            <option key={type} value={type} name={type}>{type}</option>
        )
    });

    return (
        <>
            <form action={window.location.origin + endpoint} className={"form"}>
                <div className={"form__filter form__filter--active"}>
                    <div className={"form__column"}>
                        <label className="form__label">Type:</label>
                        <select name="filter" id="docTypes" className={"form__input--filters"} onChange={handleDocFilterChange}>
                            <option name="all" value="all">All Documents</option>
                            {getDocTypes}
                        </select>
                    </div>

                    <div className={"form__row form__row--align-flexend"}>
                        <div className={"form__column"}>
                            <label className="form__label">Timeframe:</label>
                            <select name="date" id="date" data-filter={"timeframe-range"} className={"form__input--filters"} onChange={handleDateFilterChange}>
                                <option value="all" data-filter-option={"all"}>All Time</option>
                                <option value="quarter" data-filter-option={"quarter"}>Current Quarter</option>
                                <option value="ytd" data-filter-option={"ytd"}>Year-to-Date</option>
                                <option value="custom" data-filter-option={"custom"}>Custom</option>
                            </select>
                        </div>

                        <input type="submit" value="Filter" className="button button--blue-opaque form__filter--button"/>
                    </div>

                </div>
                {/*todo keep the custom range line showing if custom was selected on previous filter*/}
                <div className={"form__filter form__row--justify-space-between"} id={"custom-date-range"}>
                        <div className={"form__column"}>
                            <label className="form__label">Custom Dates: </label>
                            <div className={"form__row form__row--align-center"}>
                                <input type="date" id="start" name="start_date" className={"form__input--filters"} value={currentCustomStartDate} max={new Date()} onChange={handleCustomStartDateChange} disabled={customEnableState}/>
                                <label className={"form__label--large"}>to</label>
                                <input type="date" id="end" name="end_date" className={"form__input--filters"} value={currentCustomEndDate} max={new Date()} onChange={handleCustomEndDateChange} disabled={customEnableState}/>
                            </div>
                        </div>
                </div>

            </form>

            <Table setError={setError} endpoint={props.endpoint} endpointAppend={props.endpointAppend}
                   tableData={currentData} columnTypes={columnTypes} headers={headers} setCurrentData={setCurrentData}
                   auxColumns={props.auxColumns}/>
        </>
    )
}

export default DocViewToggle;
