import React from 'react';
import {func, array, object, string} from 'prop-types';
import {useViewRow} from "../../util/useViewRow";

const ViewButton = props => {
    const {data, setCurrentData, tableData, setError, endpoint, ...restProps} = props;
    const viewRowFn = useViewRow({
        endpoint,
        setError
    });
    return (
        <td className={"table__data form__icon--column no-print"}>
            <button onClick={() => {
                viewRowFn({data, setCurrentData, currentData: tableData})
            }} className={"form__icon form__icon--button form__icon--button-view"}>
            </button>
        </td>
    );
};

ViewButton.propTypes = {
    endpoint: string,
    setCurrentData: func,
    data: object,
    tableData: array,
    setError: func
}

export default ViewButton;
