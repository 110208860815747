import React, {useState} from 'react';

const DeleteCheckbox = props => {
    return (
        <td key={props.data.id} className={"table__data table__delete-selector"}>
            <input type={"checkbox"} data-delete-item={props.data.id} name={"delete_" + props.data.id}/>
        </td>
    );
};

export default DeleteCheckbox;
