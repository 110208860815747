import React, {useState} from 'react';

const AccordionItem = props => {
    const {property, investData} = props;

    const propertyImage = (
        <img src={property['property_image']} alt=""/>
    )

    return (
        <>
            {propertyImage}
        </>
    );
}

export default AccordionItem;
