import React, {useState, useRef} from 'react';

const DocumentLine = props => {
    const {docTypes, users, index, removeFunction} = props;
    const [userEmail, setUserEmail] = useState('');
    const [docType, setDocType] = useState('');
    const [docName, setDocName] = useState('');
    const [label, setLabel] = useState('Select a file...');
    const fileRef = useRef();

    const getDocTypesOptions = Object.keys(docTypes).map(type => {
        return (
            <option key={type} value={docTypes[type]}>{docTypes[type]}</option>
        );
    });

    const getEmails = Object.keys(users).map(userID => {
        return (
            <option key={users[userID].email} value={users[userID].email}>{users[userID].email}</option>
        );
    })

    const setFileName = (event) => {
        const label = event.target.nextElementSibling;
        const labelVal = label.innerHTML;
        const fileName = event.target.value.split('\\').pop();

        if (fileName)
            setLabel(fileName);
        else
            setLabel(labelVal);
    }

    return (
        <div key={index} className={"form__row form__row--justify-center form__row--align-center"}>
            <button onClick={() => {
                removeFunction(index)
            }} type={'button'} className={"form__icon form__icon--button form__icon--button-delete-row"}/>

            <div className="form__column form__column--flex-grow">
                <label htmlFor={"email[" + index + "]"} className={"form__label"}>Investor Email</label>
                <select name={"email[" + index + "]"} className={"form__input"}
                        onChange={event => setUserEmail(event.target.value)} value={userEmail} required={true}>
                    <option value="">Select User...</option>
                    {getEmails}
                </select>
            </div>

            <div className="form__column form__column--flex-grow">
                <label htmlFor={"doc_type[" + index + "]"} className={"form__label"}>Document Type</label>
                <select name={"doc_type[" + index + "]"} className={"form__input"}
                        onChange={event => setDocType(event.target.value)} value={docType} required={true}>
                    <option value="" className={"form__input"}>Document Type...</option>
                    {getDocTypesOptions}
                </select>
            </div>

            <div className={"form__column form__column--flex-grow"}>
                <label htmlFor={"doc_name[" + index + "]"} className={"form__label"}>Document Name</label>
                <input type="text" name={"doc_name[" + index + "]"} className={"form__input"}
                       onChange={event => setDocName(event.target.value)} value={docName} placeholder={"Document Name"}
                       required={true}/>
            </div>

            <input type="file" name={"doc_upload[" + index + "]"} className={"form__file-upload"}
                   onChange={event => setFileName(event)} key={index} ref={fileRef} id={"doc_upload[" + index + "]"}/>
            <label htmlFor={"doc_upload[" + index + "]"}><span
                className={"form__file-upload-custom"}>{label}</span></label>
        </div>
    );
}

export default DocumentLine;
