function MobileSideBarNav() {
    this.init = function() {
        const self = this;
        this.menuIcon = document.getElementById('side-nav-icon');
        this.menu = document.getElementById('side-nav');
        if (!this.menu || !this.menuIcon) {
            return;
        }
        this.menuIcon.addEventListener('mouseover', self.handleNavHover.bind(self));
        document.addEventListener('mouseover', self.handleDocHover.bind(self));
    };

    this.handleNavHover = function() {
        this.menu.classList.add('dashboard__menu--container-visible');
    };

    this.handleDocHover = function(ev) {
        const isClickInside = this.menuIcon.contains(ev.target) || this.menu.contains(ev.target);
        if (!isClickInside) {
            this.menu.classList.remove('dashboard__menu--container-visible');
        }
    };
}

window.bridgeMobileSideNav = new MobileSideBarNav();
document.addEventListener('DOMContentLoaded', window.bridgeMobileSideNav.init.bind(window.bridgeMobileSideNav));
