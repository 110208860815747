let accordion = document.getElementsByClassName("accordion__button");

for (let i = 0; i < accordion.length; i++) {
    accordion[i].addEventListener("click", function(ev) {
        this.classList.toggle("active");
        let content = this.nextElementSibling;
        content.classList.toggle('active');
    });
}

function removeActive() {
    for (const index in accordion) {
        if (accordion.hasOwnProperty(index)) {
            accordion[index].classList.remove('active');
            accordion[index].nextElementSibling.classList.remove('active');
        }
    }
}
