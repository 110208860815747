import {useState, useEffect} from 'react';

export const useSearchInput = args => {
    const {endpoint, searchData, initialSearchVal} = args;

    const windowParams = new URLSearchParams(document.location.search.substring(1));
    const startSearchVal = initialSearchVal ? initialSearchVal : (windowParams.get('search') == null ? '' : windowParams.get('search'));

    const [searchedData, setSearchedData] = useState(searchData);
    const [searchVal, setSearchVal] = useState(startSearchVal);

    useEffect(() => {
        const url = new URL(window.location.href);
        url.searchParams.set("search", searchVal);
        const headers = new Headers({
            "X-Requested-With": "XMLHttpRequest"
        });
        const request = new Request(url, {
            method: "GET",
            headers: headers,
            cache: "default"
        });
        history.pushState({}, "Bridge Search: " + url.searchParams.toString(), url.toString());
        fetch(request)
            .then(res => res.json())
            .then(result => {
                if (!result.exception) {
                    setSearchedData(result);
                } else {
                    console.error(result.exception, result.trace);
                }
            });
    }, [searchVal]);

    return {
        searchedData,
        setSearchedData,
        searchVal,
        setSearchVal
    }
};
