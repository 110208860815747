$(document).ready(function () {
    $('#confirmPopup').hide();

    $('[data-button]').each(function () {
        $(this).on('click', function (e) {
            $(`[data-confirm-id="${e.currentTarget.dataset['deleteId']}"]`).show();
        });
    });

    $('[data-thirdparty-id]').each(function(ev, other) {
        $(this).on('click', function (e) {
            let data = e.currentTarget.dataset.thirdpartyId;
            data = JSON.parse(data);
            let cardElement = $(`[data-card-element="${e.currentTarget.dataset.thirdpartyId}"]`);
            $.ajax({
                type: "GET",
                url: `/investor/account/third-parties/${data}/delete`,
                data: data
            });
            cardElement.remove();
            $(`[data-confirm-id="${data}"]`).hide();
        });
    });

    $('[data-cancel-id]').each(function() {
        $(this).on('click', function (e) {
            $(`[data-confirm-id="${e.currentTarget.dataset['cancelId']}"]`).hide();
        })
    });

});
