import {getStringKeys} from "./getStringKeys";

/**
 * Get a function to use when generating an AUXILIARY column row
 * @param args
 * @returns {function(...[*]=)}
 */
export const useViewRow = (args) => {
    const {endpoint, setError} = args;
    const replaceableKeys = getStringKeys(window.location.origin + '{filepath}');
    return (calltimeArgs) => {
        const {data, setCurrentData, currentData} = calltimeArgs;
        let replacedUrl = window.location.origin + '{filepath}';
        Object.keys(replaceableKeys).map(key => {
            if (data['document_filepath']) {
                replacedUrl = replacedUrl.replaceAll(replaceableKeys[key], data['document_filepath']);
            }
        });
        window.open(replacedUrl, '_blank');
    };
};
