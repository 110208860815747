import React, {useEffect, useMemo, useState} from 'react';
import {array} from "prop-types";
import classNames from "classnames";

const ColumnSelector = props => {
    const {
        headers,
        page,
        setActiveCols
    } = props;
    const [isVisible, setIsVisible] = useState(false);
    const columns = Object.keys(headers);
    const activeCols = () => {
        return columns.map(col => {
            const localStorageState = localStorage.getItem(page + col);
            return {'name': col, 'active': localStorageState === 'true'};
        }).filter(col => {
            return !!col.active;
        });
    };
    useEffect(() => {
        columns.forEach(col => {
            const localStorageState = localStorage.getItem(page + col);
            if (localStorageState === null) {
                localStorage.setItem(page + col, true);
            }
        });
        setActiveCols(
            activeCols().map(activeCol => activeCol.name)
        );
    }, []);

    const updateColumns = (e) => {
        e.preventDefault();
        localStorage.setItem(page + e.target.id, e.target.checked);
        setActiveCols(
            columns.filter(col => {
                return localStorage.getItem(page + col) === 'true';
            })
        );
    };
    const allColumns = columns.map(col => {
        const localStorageState = localStorage.getItem(page + col);
        return {'name': col, 'active': localStorageState === 'true'};
    });
    return (
        <div className={"columnSelector"}>
            <button onClick={() => {setIsVisible(prevState => !prevState)}} className={"columnSelector__show button"}>
                Set Visible Columns
            </button>
            <div className={classNames("columnSelector__columns", {"columnSelector__columns--visible": isVisible})}>
                <div className="form__row form__row--justify-space-between">
                    <h3 className="header3 header3--blue columnSelector__popup--header">Select Columns to Show/Hide</h3>
                    <img src="/images/delete-grey.png" alt="delete button" className={"columnSelector__popup--close"}
                         onClick={() => {
                             setIsVisible(!isVisible)
                         }}/>
                </div>
                {columns.map((column, idx) => {
                    return (<div className={"columnSelector__column"} key={idx}>
                        <label className={"columnSelector__label"} htmlFor={column}>{Object.entries(headers).find(([col, nicename]) => col === column)[1]}</label>
                        <input onInput={updateColumns} defaultChecked={!allColumns.find(col=> !col.active && col.name === column)} type={"checkbox"} className={"columnSelector__columnInput"} id={column}/>
                    </div>);
                })}
            </div>
        </div>
    );
};

ColumnSelector.propTypes = {
    columns: array
}

export default ColumnSelector;
