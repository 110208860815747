import React, {useState, useCallback} from 'react';
import ReactDOM from "react-dom";
import DocumentLine from "./DocumentLine";
import getMetaTags from '../../../util/getMetaTags';

const DocumentsForm = props => {
    const removeFunction = (lineKey) => {
        const filteredLines = lines.filter((line) => {
            return line.key !== lineKey;
        });
        setLines(filteredLines);
    };
    const [linekey, setLinekey] = useState(0);
    const [lines, setLines] = useState([{...props, index: linekey, key: linekey}]);
    const tags = getMetaTags();

    const handleCopyButtonClick = (event) => {
        setLines([...lines, {...props, index: linekey + 1, key: linekey + 1}]);
        setLinekey(linekey + 1);
    };

    const submitButton = (
        <input type="submit" value="Submit" className="button button--blue-opaque"/>
    );

    const csrfToken = tags.filter((tag) => {return tag.name === "csrf-token"});

    return (
        <form action={"/admin/documents"} method={"POST"} encType={"multipart/form-data"}>
            <input type="hidden" value={csrfToken[0].content} name={"_token"}/>
            {lines.map((line) => {
                return (<DocumentLine {...line} removeFunction={removeFunction}/>);
            })}
            <div className={"form__row no-print"}>
                <button type={'button'} className={"button button--blue-white form__button--add-row"} onClick={handleCopyButtonClick}>
                    <img src="/images/add.png" alt="add button" className={"form__icon--button-add"}/>
                    Add Row
                </button>
                {submitButton}
            </div>
        </form>
    );
}

if (document.querySelector('[data-react-component="DocumentsForm"]')) {
    ReactDOM.render(<DocumentsForm {...tableReactData} />, document.querySelector('[data-react-component="DocumentsForm"]'));
}

export default DocumentsForm;
